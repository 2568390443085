<template>
    <el-form ref="detail" :model="detail" label-position="left" :rules="rules" size="mini">
        <div class="title"><i class="el-icon-collection-tag" />必填基本资料</div>
        <div class="form3">
            <el-form-item label="客户名称" prop="customer_name">
                <el-input v-model="detail.customer_name" class="w200" placeholder="请填写客户姓名" />
            </el-form-item>
            <el-form-item label="性别" prop="customer_sex">
                <el-select v-model="detail.customer_sex" placeholder="请选择">
                    <el-option v-for="item in options.sex" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="生日" prop="customer_birthday">
                <el-date-picker type="date" placeholder="选择日期" v-model="detail.customer_birthday" style="width: 100%;"></el-date-picker>
            </el-form-item>

            <el-form-item label="病历号" prop="heal_number">
                <el-input v-model="detail.heal_number" disabled="true" class="w200" placeholder="" />
            </el-form-item>
            <el-form-item label="就诊日期" prop="heal_date">
                <el-date-picker type="date" placeholder="选择就诊日期" v-model="detail.heal_date" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="主诊医生" prop="doctor">
                <el-input v-model="detail.doctor" class="w200" placeholder="请填写主诊医生" />
            </el-form-item>

        </div>

        <div class="form1">
            <el-form-item label="主诉">
                <el-input v-model="detail.text1" class="w600" placeholder="请填写主诉" />
            </el-form-item>

            <el-form-item label="既往过敏史">
                <el-input v-model="detail.text2" class="w600" placeholder="请填写既往过敏史" />
            </el-form-item>

            <el-form-item label="现病史" prop="">
                <el-input v-model="detail.text3" class="w600" placeholder="请填写现病史" />
            </el-form-item>

            <el-form-item label="既往史" prop="">
                <el-input v-model="detail.text4" class="w600" placeholder="请填写既往史" />
            </el-form-item>

            <el-form-item label="家族史" prop="">
                <el-input v-model="detail.text5" class="w600" placeholder="请填写家族史" />
            </el-form-item>

            <el-form-item label="皮肤检查" prop="">
                <el-input v-model="detail.text6" class="w600" placeholder="请填写皮肤检查" />
            </el-form-item>
            <el-form-item label="辅助检查" prop="">
                <el-input v-model="detail.text7" class="w600" placeholder="请填写辅助检查" />
            </el-form-item>
            <el-form-item label="诊断" prop="">
                <el-input v-model="detail.text8" class="w600" placeholder="请填写诊断" />
            </el-form-item>

            <!-- 医嘱 -->
            <el-form-item label="医嘱">
                <el-select v-model="detail.text9" multiple placeholder="请选择医嘱" class="w600">
                    <el-option v-for="item in options.doctorAdvice" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="处置" prop="">
                <el-input v-model="detail.text10" class="w600" placeholder="请填写处置" />
            </el-form-item>
            <el-form-item label="下次治疗建议" prop="">
                <el-input v-model="detail.text11" class="w600" placeholder="请填写下次治疗建议" />
            </el-form-item>
            
            <el-form-item label="治疗方案">
                <el-select v-model="detail.heal_plan" placeholder="请选择治疗方案" class="w600">
                    <el-option v-for="item in options.heal_plan" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="面诊后1至3个月治疗规划" prop="">
                <el-input v-model="detail.text12" class="w600" placeholder="" />
            </el-form-item>

        </div>

        <div class="btnBox">
            <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">提交</el-button>
        </div>
    </el-form>

</template>
  
<script>
import { mapGetters } from 'vuex'
import region from "@/components/Region";
import {
  addHealPlan,
  getHealPlan,
} from "@/api/client";

class Detail {
    customer_id = '';
    customer_name = '';
    customer_sex = '';
    customer_birthday = '';
    heal_number = '';
    heal_date = '';
    doctor = '';
    text1 = '';
    text2 = '';
    text3 = '';
    text4 = '';
    text5 = '';
    text6 = '';
    text7 = '';
    text8 = '';
    text9 = [];
    text10 = '';
    text11 = '';
    text12 = '';

}
class Rules {
    customer_name = [
        { required: true, message: "请填写客户名称", trigger: "blur" },
    ];
    customer_sex = [
        { required: true, message: "请选择客户性别", trigger: "blur" },
    ];
    customer_birthday = [
        { required: true, message: "请选择客户生日", trigger: "blur" },
    ];
    // heal_number = [
    //     { required: true, message: "请填写病历号", trigger: "blur" },
    // ];
    heal_date = [
        { required: true, message: "请选择就诊日期", trigger: "blur" },
    ];
    doctor = [
        { required: true, message: "请填写主诊医生", trigger: "blur" },
    ];
}
class Options {
    heal_plan = [
        {id:1,name:'Plasma治疗'},
        {id:2,name:'强脉冲多模式治疗'},
        {id:3,name:'分解色素治疗'},
        {id:4,name:'祛红阻断红血丝'},
        {id:5,name:'皮下剥离增容术'},
        {id:6,name:'疤痕胶原降解'},
        {id:7,name:'Plasma透皮治疗'},
        {id:8,name:'负压导入'},
        {id:9,name:'胶原再生'},
        {id:10,name:'屏障修复'},
        {id:11,name:'黄金微针'},
        {id:12,name:'红蓝光'},
        {id:13,name:'湿性愈合'},
        {id:14,name:'换药'},
    ];
    doctorAdvice = [
        {id:1,name:'治疗后前三天每天换包扎，并传照片观察。三天以后可以根据情况安排用药。'},
        {id:2,name:'换药期间要注意消毒，无菌操作，避免感染。'},
        {id:3,name:'一个月左右来医院复查，安排下次治疗。'},
        {id:4,name:'注意饮食:禁辛辣刺激食物，面部注意补水保湿防晒。'},
    ];
    sex = [
        {id:1,name:"男"},
        {id:2,name:"女"},
    ];
}

export default {
    components: {
        region,
    },

    data() {
        return {
            detail: new Detail(),
            rules: new Rules(),
            options: new Options(), // 选项
            custoemr_id: '',
            healId:'',
        };
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    watch: {},
    mounted() {
        console.log(this.$route.query);
        this.custoemr_id = this.$route.query ? this.$route.query.custoemr_id : 0;
        this.healId = this.$route.query ? this.$route.query.heal_id : 0;
        this.getHealPlan(this.custoemr_id);
    },
    methods: {
        // 获取客户治疗方案
        getHealPlan(id){
            getHealPlan({ customer_id:id }).then((res) => {
                if(res.data){
                    this.detail = res.data;
                }
            });
        },
        // 提交
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // if (!this.detail.heal_date.length) {
                    //     this.$message({
                    //         message: "请添加疤痕资料",
                    //         type: "error",
                    //     });

                    //     return false;
                    // }

                    this.hint(this.id ? "更新" : "添加");

                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        // 提示
        hint(text) {
            this.$confirm(`确定${text}此客户信息吗？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.confirm();
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消",
                });
            });
        },
        // 确认提交
        confirm() {
            console.log(this.healId);
            // return
            if (this.healId) {
                addHealPlan({ ...this.detail,healId:this.healId }).then((res) => {
                    this.$message({
                        type: "success",
                        message: res.msg,
                    });
                    this.back();
                });
            } else {
                addHealPlan({ ...this.detail,customer_id:this.custoemr_id,healId:'' }).then((res) => {
                    console.log(res);
                    this.$message({
                        type: "success",
                        message: res.msg,
                    });
                    this.$router.go(-1);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: bold;
    font-size: 14px;
    line-height: 34px;
    color: #666;
    margin-bottom: 20px;
}

.form3 {
    display: flex;
    flex-wrap: wrap;

    ::v-deep .el-form-item {
        margin-right: 20px;
        display: flex;
    }

    ::v-deep .el-form-item {
        width: 30%;
    }

    ::v-deep .el-form-item--mini .el-form-item__label {
        width: 100px;
    }

    ::v-deep .region .el-select {
        width: 100px;
    }
}
.form1{
    display: flex;
    flex-wrap: wrap;

    ::v-deep .el-form-item {
        margin-right: 20px;
        display: flex;
    }

    ::v-deep .el-form-item {
        width: 100%;
    }

    ::v-deep .el-form-item--mini .el-form-item__label {
        width: 112px;
    }

    ::v-deep .region .el-select {
        width: 100px;
    }
}

.btnBox {
    width: 100%;
    text-align: center;

    .mainBtn {
        padding: 6px 40px;
        margin-top: 20px;
    }
}

.center {
    width: 100% !important;
}

.scarBox {
    display: flex;

    .imgBox {
        margin-left: 100px;
    }
}
</style>
  